/*Header---------------------------------------------------------------*/
let scrollpos = window.scrollY;
const screen_width = screen.width;

let menu = document.querySelector('.header');
let close_btn = document.querySelector('.header__hamburguer-close');
let open_btn = document.querySelector('.header__btn');
let menu_hamburguer = document.querySelector('.header__hamburguer');

let footer_menu = document.querySelector('.footer__menu');
let footer_links = document.querySelector('.footer__links');
let footer_contact= document.querySelector('.footer__contact');
let footer_items = document.querySelector('.footer__items');

/*Abrir e fechar header mobile*/
close_btn.addEventListener('click', function() {
    menu_hamburguer.classList.add('header__hamburguer--close')
})

open_btn.addEventListener('click', function() {
    menu_hamburguer.classList.remove('header__hamburguer--close')
})

/*Scroll*/
function add_class_on_scroll() {
    menu.classList.remove("header--fixed");
}

function remove_class_on_scroll() {
    menu.classList.add("header--fixed");
}

window.addEventListener('scroll', function(){ 
    scrollpos = window.scrollY;

    if(scrollpos > 10){
        add_class_on_scroll();
    }
    else {
        remove_class_on_scroll();
    }
});

/*Carrouels*/
$('.banner__layer').slick({
    infinite: false,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
});

$('.post__associated').slick({
    infinite: false,
    arrows: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    nextArrow: '<div class="slick-next"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><path d="M9.375 18.75L15.625 12.5L9.375 6.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>,  </div>',
    prevArrow: '<div class="slick-prev">  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><path d="M15.625 18.75L9.375 12.5L15.625 6.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg> </div>',
    responsive: [
        {
            breakpoint: 556,
            settings: {
              dots: true,
              arrows: false,
            }
        },
    ]
});

$('.products__carrousel').slick({
    infinite: false,
    arrows: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    nextArrow: '<div class="slick-next"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><path d="M9.375 18.75L15.625 12.5L9.375 6.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>,  </div>',
    prevArrow: '<div class="slick-prev">  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><path d="M15.625 18.75L9.375 12.5L15.625 6.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg> </div>',

    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToScroll: 3,
            infinite: true,
            variableWidth: true,
          }
        },
        {
            breakpoint: 556,
            settings: {
              slidesToShow: 1,
              variableWidth: true,
              infinite: true,
            }
        },
    ]
});

if(screen_width < 1024){
    footer_menu.addEventListener('click', function() {
        footer_links.classList.toggle('footer__links--active')
        footer_menu.classList.toggle('footer__menu--active')
    })

    footer_contact.addEventListener('click', function() {
        footer_items.classList.toggle('footer__items--active')
        footer_contact.classList.toggle('footer__contact--active')
    })
}

let openYotube = document.querySelector('.single__youtube');
let modal = document.querySelector('.modal');
let body = document.querySelector('body');

if(openYotube){
    openYotube.addEventListener('click' , () => {
        modal.classList.toggle('show')

        if(modal.classList.contains('show')){
            body.style.overflow = 'hidden';
        }
        else{
            body.style.overflow = 'auto';

        }
    })
}

let openShare = document.querySelector('.post__share');
let modal_share = document.querySelector('.modal_share');


if(openShare){
    openShare.addEventListener('click' , () => {
        console.log('click')
        modal.classList.toggle('show')

        if(modal.classList.contains('show')){
            body.style.overflow = 'hidden';
        }
        else{
            body.style.overflow = 'auto';

        }
    })
}

if(modal_share) {
    modal_share.addEventListener('click' , () => {
        modal_share.classList.toggle('show')

        if(modal_share.classList.contains('show')){
            body.style.overflow = 'hidden';
        }
        else{
            body.style.overflow = 'auto';

        }
    })
}